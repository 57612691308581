.logout_wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    box-shadow: 100px 100px 80px rgb(0 0 0 / 4%);
    bottom: 0;
    background: #fff;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    color: #000 !important;;
}

.logo_wrapper{
    width: 150px;
}